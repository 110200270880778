
import React, {useCallback} from 'react';

import {WorksItem} from 'fetch/user';
import { polyIcon } from 'assets';
import WorksPlay from '../WorksPlay';

import SimpleModal from 'components/Modal/simpleModal';
import s from './index.module.scss';
interface Props {
  data: WorksItem
}

type BgMap = {
  [key: string]: string
}

const CodeLanguageBg: BgMap = {
  blockly: '#FD6059',
  python: '#22A0FC',
  scratch: '#885DFF',
}

const CodeLanguageText: Record<string, string> = {
  blockly: 'blockly',
  python: 'python',
  scratch: '图形化',
}

const WorksCard: React.FC<Props> = ({
  data
}) => {

  const handleCloseModal = useCallback(() => {
    SimpleModal.close()
  }, [])

  const handleOpenModal = () => {
    SimpleModal.show(<WorksPlay data={data} closeModal={handleCloseModal}/>)
  }

  const bgColor = CodeLanguageBg[data.codeLanguage];

  return (
    <>
      <div className={s.wrap}>
        <div className={s.type} style={{background: bgColor}}>{CodeLanguageText[data.codeLanguage]}</div>
        <div className={s.imageWrap} onClick={handleOpenModal}>
          <div className={s.grey}>
            <div className={s.iconWrap}>
              <img 
                className={s.icon} 
                src={polyIcon} 
                alt="播放按钮"
              />
            </div>
          </div>
          <img className={s.image} src={data.imgUrl} alt="作品图片" />
        </div>
        <div className={s.title}>{data.name}</div>
        <div className={s.desc}>{data.introduction}</div>
      </div>
    </>
  )
}


export default React.memo(WorksCard);