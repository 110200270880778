import React from 'react'
import WorkCard from './workcard'
import s from './index.module.sass'
import { arrGroup } from '../../util'

export interface WorkListItem {
  id: string
  title: string
  imgUrl: string
  likeNum: number
  browseNum: number
  userName: string
  userImage: string
  status?: number
  codeLanguage?: string
  subTheme?: string
}

export interface IWorkListProps {
  listData: WorkListItem[]
  isSelf: boolean
  hideUser?: boolean
  config?: configProps
  onClickWorkListItem?: (item: WorkListItem, type?: string) => void
}

export interface configProps {
  containerWidth?: number
  itemWidth?: number
}

export interface WorkListData {
  total?: number
  list: WorkListItem[]
}

function WorkList({ listData, isSelf, hideUser, config = {}, onClickWorkListItem }: IWorkListProps) {
  const getGroupedList = (count: number) => {
    return arrGroup(listData, count)
  }
  const renderListChild = () => {
    const ITEM_WIDTH = 220
    const containerWidth = config?.containerWidth || 1200
    const count = Math.floor(containerWidth / ITEM_WIDTH)
    const margin =
      count === 1 ? 0 : (containerWidth - count * ITEM_WIDTH) / (count - 1)
    const groupedData = getGroupedList(count)

    return groupedData.map((row, index) => {
      return (
        <div className={s['workcards-box-row']} key={index}>
          {row.map((item) => {
            return (
              <div
                className={s['workcards-box-row-item']}
                style={{ marginRight: `${margin}px`, width: ITEM_WIDTH + 'px' }}
                key={item.id}
              >
                <WorkCard
                  data={item}
                  id={item.id}
                  title={item.title}
                  imgUrl={item.imgUrl}
                  likeNum={item.likeNum}
                  browseNum={item.browseNum}
                  userName={item.userName}
                  avatarUrl={item.userImage}
                  isSelf={isSelf}
                  hideUser={hideUser}
                  status={item.status}
                  onWorkCard={(_, type) => {
                    if (onClickWorkListItem) {
                      onClickWorkListItem(item, type)
                    }
                  }}
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  return <div className={s['workcards-box']}>{renderListChild()}</div>
}

export default WorkList
