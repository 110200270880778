import { useEffect, useState, useContext } from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'
import { WithRouterProps } from 'next/dist/client/with-router'
import Header from 'components/Header'
import Footer from 'components/Footer'
import WorkList, { WorkListItem } from 'components/WorkList'
import WeeklyWorkCard from 'components/WorkList/weeklyworkcard'
import Slide from 'components/Slide'
import { UserContext } from 'components/UserProvider'
import { YDLog } from 'components/YDLog'
import ColumnHeader from './home/ColumnHeader';
import { 
  rank1, rank2, rank3, rank4, rank5, 
  scratchBadge, pythonBadge, blocklyBadge } from '../assets'
import { getHomeData, getPopupData } from '../fetch'
import { PreviewPagePath, DiscoveryPagePath } from '../pagerouter'
import { isYdCourseApp, enhancedEventKeRlog } from '../util'
import { SCRATCH_HOST, LOG, COMPLIANCE } from '../util/constants'
import s from './index.module.scss'
import { useYdCourseApp } from 'hooks/useYdCourseApp'
import { useTuringApp } from 'hooks/useTuringApp'

import TemplateWorks from './home/TemplateWorks';
import IfRender from 'components/IfRender'

const FROM_KEY_STRING = 'icodeshequ_banner_bottom'
const FROM_STRING = LOG.visit_ide[FROM_KEY_STRING]

interface IHomeProps {
  query: {
    page?: number | string
    from?: string
  }
  initModel?: {
    newestWorksList?: WorkListItem[]
    featuredWorksList?: WorkListItem[]
    weeklyPopularWorksList?: WorkListItem[]
    templateList?: []
    bannerList?: {
      imgUrl: string
      linkUrl: string
    }[]
  }
}

function Home(props: IHomeProps & WithRouterProps) {
  const [homeData, setHomeData] = useState<any>(props.initModel)
  const { user, dispatch } = useContext(UserContext)
  const { isYdCourseAppWebView } = useYdCourseApp(user)
  const { isTuringAppWebView } = useTuringApp()
  
  const fetcher = (abort?: any) => {
    let aborted = false
    if (abort) {
      abort.cancel = () => {
        aborted = true
      }
    }
    return getHomeData().then((data) => {
      if (aborted) {
        return
      }
      setHomeData(data)
    })
  }

  useEffect(() => {
    const abort: any = {}
    if (homeData == null) {
      fetcher({ abort })
    }
    return () => {
      if (abort.cancel) {
        abort.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (!isYdCourseApp()) {
      getPopupData().then((res) => {
        const { imgUrl, linkUrl } = res[0] || {}
        // 调用popadsdk弹出广告窗
        if ((window as any).popads && imgUrl && linkUrl) {
          (window as any).popads(imgUrl, linkUrl)
        }
      })
    }
  }, [])

  // 埋点
  useEffect(() => {
    enhancedEventKeRlog('page_enter', {
      page_name: '创作社区首页',
      from:
        (LOG.visit_index as Record<string, string>)[props.query.from as string] ||
        '其他',
    })
  }, [props.query])

  const renderRecommend = () => {
    return (
      <div className={s.recommend}>
        <ColumnHeader
          title="精选推荐"
          desc="有趣的灵魂值得被推荐！"
          morelink={DiscoveryPagePath}
        />

        {homeData?.featuredWorksList ? (
          <WorkList
            isSelf={false}
            listData={homeData?.featuredWorksList}
            config={{ containerWidth: 710 }}
            onClickWorkListItem={(item) => {
              props.router.push({
                pathname: PreviewPagePath + item.id,
                query: { from: 'recommend' }
              })
            }}
          />
        ) : null}
      </div>
    )
  }

  const renderWeeklyWork = (item: WorkListItem, index: number) => {
    const { id, title, imgUrl, likeNum, browseNum, userImage, userName } = item
    const badgeImgs = [rank1, rank2, rank3, rank4, rank5]
    return (
      <div className={s.rankContainer} key={id}>
        <WeeklyWorkCard
          id={id}
          title={title}
          imgUrl={imgUrl}
          likeNum={likeNum}
          browseNum={browseNum}
          userName={userName}
          avatarUrl={userImage}
          onClick={() => {
            props.router.push({
              pathname: PreviewPagePath + id,
              query: { from: 'rank' }
            })
          }}
        >
          <img src={badgeImgs[index]} className={s.rankTopImg} />
        </WeeklyWorkCard>
      </div>
    )
  }

  const renderWeeklyPopular = () => {
    return (
      <div className={s.weekly}>
        <div className={s.weeklyHeader}>
          {/* <div className={s.columnTitle}>人气周榜</div>
          <div className={s.columnDesc}>每周都有新灵感！</div> */}
          <ColumnHeader title="人气周榜" desc="每周都有新灵感！" />
        </div>
        <div className={s.weeklyBody}>
          {homeData?.weeklyPopularWorksList.map(
            (item: WorkListItem, index: number) => renderWeeklyWork(item, index)
          )}
        </div>
      </div>
    )
  }

  const renderNewest = () => {
    return (
      <div className={`restrict-width height-grow ${s.newest}`}>
        <ColumnHeader
          title="最新作品"
          desc="勤能补拙～你的作品总能被发现！"
          morelink={DiscoveryPagePath}
        />

        {homeData?.newestWorksList ? (
          <WorkList
            isSelf={false}
            listData={homeData?.newestWorksList}
            onClickWorkListItem={(item) => {
              props.router.push({
                pathname: PreviewPagePath + item.id,
                query: { from: 'new' }
              })
            }}
          />
        ) : null}
      </div>
    )
  }

  const renderWorkSelect = () => (
    <div className={s.workSelectWrap}>
    <div className={s.workSelect}>
      <YDLog click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'blockly' } }}>
        <div
          className={`${s.work} ${s.workBlockly}`}
          onClick={() => dispatch({ type: 'showThemeModal', from: FROM_KEY_STRING })}
        >
          <img src={blocklyBadge} className={s.workBadge} />
          Blockly图形化创作
        </div>
      </YDLog>
      <div className={s.divide}></div>
      <YDLog click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'scratch' } }}>
        <Link href={`${SCRATCH_HOST}?from=${FROM_KEY_STRING}`}>
          <div className={`${s.work} ${s.workScratch}`}>
            <img src={scratchBadge} className={s.workBadge} />
            图形化创作
          </div>
        </Link>
      </YDLog>
      <div className={s.divide}></div>
      <YDLog click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'python' } }}>
        <Link href={`/workspace/py?from=${FROM_KEY_STRING}`}>
          <div className={`${s.work} ${s.workPython}`}>
            <img src={pythonBadge} className={s.workBadge} />
            Python创作
          </div>
        </Link>
      </YDLog>
    </div>
    </div>
  )

  return <>
    <div style={{position: 'relative'}}>
      <div className={s.headerCt}>
        <Header noBackground={true} logoColored={true} />
      </div>
      <Slide>
        {homeData?.bannerList.map((banner:any, index: number) => (
          <div key={index}>
            <a href={banner?.linkUrl} target="_blank" rel="noreferrer" onClick={(e) => {
              if (isYdCourseAppWebView && isTuringAppWebView) {
                e.preventDefault()
              }
            }}>
              <div className={s.homeBanner}>
                {/* todo:处理img样式 */}
                <img src={banner?.imgUrl} alt='' className={s.bannerImg} />
              </div>
            </a>
          </div>))}
      </Slide>
      {renderWorkSelect()}
    </div>
    <div className={s.container}>
      <div className={s.templates}>
      {
        homeData.templateList?.length && <TemplateWorks data={homeData.templateList} />
      }
      </div>
      <IfRender show={!COMPLIANCE}>
        <>
          <div className={s.columnContainer}>
            {renderRecommend()}
            {renderWeeklyPopular()}
          </div>
          {renderNewest()}
          <div className={s.pagerCt}></div>
        </>
      </IfRender>
      {!isYdCourseAppWebView && !isTuringAppWebView && <Footer />}
    </div>
  </>
}

export default withRouter(Home)

export async function getServerSideProps(context: any) {
  let data = null
  try {
    data = await getHomeData()
  } catch (err) {
    // 别坑我
  }

  return {
    props: {
      query: context.query,
      initModel: data,
    },
  }
}
