import { memo } from 'react'
// @ts-ignore
import s from './Button.module.sass'

export interface Button {
  onClick?: React.MouseEventHandler
  text: string
}

export default memo(function Button(props: Button) {
  return <div className={s['button']} onClick={props.onClick}>
    <div className={s.bg}>

    </div>
    <div className={s.text}>
      {props.text}
    </div>
  </div>
})