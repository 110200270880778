import React, { memo, ReactNode } from 'react'
import Slider from 'react-slick'
import s from './index.module.css'

export interface SlideProps {
  children?: ReactNode
}

const settings = {
  speed: 500,
  dots: true,
  autoplay: true,
  initialSlide: 0,
  dotsClass: s.customSlickDots,
  // eslint-disable-next-line react/display-name
  customPaging: () => (<div className='slickCustomDots'></div>)
}

const Slide = ({children}: SlideProps) => {

  return (
    <div className={s.wrap}>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://shared.ydstatic.com/ke/sdk/slick/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://shared.ydstatic.com/ke/sdk/slick/slick-theme.min.css"
      />
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default memo(Slide)
