import { ReactElement } from 'react';
import ReactDom from 'react-dom';

let dom: any = null;

class SimpleModal {
  static show(content: ReactElement) {

    if(!dom) {
      dom = document.createElement('div');
      dom.setAttribute('class', 'simple-modal-wrap')
    }
    document.body.appendChild(dom);
    ReactDom.render(content, dom)
  }

  static close() {
    ReactDom.unmountComponentAtNode(dom);
    document.body.removeChild(dom);
  }
}


export default SimpleModal;