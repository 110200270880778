import React, { memo, useMemo } from 'react'
import Avatar from '../Avatar'
import IfRender from 'components/IfRender'

/** @ts-ignore */
import s from './workcard.module.sass'

import Button from './Button'
import { POSTER1 } from '../../cdnmap'
import { num2likestr } from '../../util'

export interface WorkCardProps {
  id: string
  title: string
  imgUrl: string
  likeNum: number
  browseNum: number
  userName: string
  avatarUrl: string
  status?: number
  hideUser?: boolean
  data?: any
}

export default memo(function WorkCard({ onWorkCard, isSelf, ...props }: WorkCardProps & {
  isSelf?: boolean
  onWorkCard?: (props: WorkCardProps, withType?: string) => void
}) {
  const imgUrl = props.imgUrl && !/\.svg/.test(props.imgUrl) ? props.imgUrl : POSTER1
  
  // 操作按钮（编辑、发布）
  const postButtons = useMemo(() => {
    if (!isSelf) {
      return null
    }
    return <div className={s['poster-btns']}>
      <div className={s['icon']} style={{ display: props.status == 1 ? '' : 'none' }} onClick={(e) => {
        e.stopPropagation()
        if (onWorkCard) {
          onWorkCard(props, '删除')
        }
      }}></div>
      <div className={s['flex-auto']}></div>
      <div>
        <Button text="编辑" onClick={(e) => {
          e.stopPropagation()
          if (onWorkCard) {
            onWorkCard(props, '编辑')
          }
        }} />
      </div>
      {props?.data?.codeLanguage === 'python' && (<div>
        <Button text={props.status == 1 ? '发布' : props.status == 2 ? '取消发布' : ''} onClick={(e) => {
          e.stopPropagation()
          if (onWorkCard) {
            onWorkCard(props, '发布')
          }
        }} />
      </div>)}
      {/* blockly和scratch作品不显示发布按钮，发布需要到各自的作品页进行 */}
      {(props?.data?.codeLanguage !== 'python' && props.status === 2) && (<div>
        <Button text={'取消发布'} onClick={(e) => {
          e.stopPropagation()
          if (onWorkCard) {
            onWorkCard(props, '发布')
          }
        }} />
      </div>)}
    </div>
  }, [isSelf, props.status])

  // 发布状态
  const absStatus = useMemo(() => {
    if (!isSelf) {
      return null
    }
    if (props.status != 1 && props.status != 2) {
      return null
    }
    return <div className={`${s['abs-btn']} ${props.status == 1 ? s['notreleased'] : s['released']}`}>
      {props.status == 1 ? '未发布' : props.status == 2 ? '已发布' : ''}
      <div className={s['abs-btn-icon']}></div>
    </div>
  }, [isSelf, props.status])


  return <div className={s['workcard-box']} onClick={(e) => {
    e.stopPropagation()
    if (onWorkCard) {
      onWorkCard(props)
    }
  }}>
    <div className={s['workcard-poster']} style={{ backgroundImage: `url(${imgUrl})` }}>
      {postButtons}
    </div>
    <IfRender show={props.data?.scoreType === 1}>
      <div className={s['workcard-selected']}>活动精选</div>
    </IfRender>
    <div className={s['workcard-title']}>{props.title || '未命名'}</div>
    <div className={s['workcard-line']}>
      <div className={`${s['workcard-icon']} ${s['eye']}`}></div>
      <div className={s['workcard-numbers']}>{num2likestr(props.browseNum)}</div>
      <div className={`${s['workcard-icon']} ${s['like']}`}></div>
      <div className={s['workcard-numbers']}>{num2likestr(props.likeNum)}</div>
    </div>
    {props?.hideUser ? null : <div className={[s['workcard-line'], s['workcard-line2']].join(' ')} style={{ display: isSelf ? 'none' : '' }}>
      <div className={s['avatar-ct']}>
        <Avatar url={props.avatarUrl} size={20} />
      </div>
      <div className={s['workcard-nickname']}>{props.userName}</div>
    </div>}
    {absStatus}
  </div >
})
