import React from 'react';

import {WorksItem} from 'fetch/user';
import { useTuringApp } from 'hooks/useTuringApp'

import s from './index.module.scss';

interface Props {
  data: WorksItem
  closeModal: () => void
}

const WorksPlay:React.FC<Props> = ({
  data,
  closeModal
}) => {
  const { isTuringAppWebView } = useTuringApp()

  const handleClick = (url: string) => {
    window.open(url, '_blank')
  }

  return <div className={s.wrap}  onClick={closeModal} >  
  <div className={s.content} onClick={e => e.stopPropagation()}>
      <video 
        id="video" 
        className={s.video} 
        controls={true} 
        height="100%" 
        width="100%"
        poster={data.imgUrl}
        controlsList="nodownload nofullscreen"
        disablePictureInPicture
        src={data.videoUrl} 
      >
      </video>
      <div className={s.footer}>
        <div className={s.descWrap}>
          <div className={s.title}>{data.name}</div>
          <div className={s.desc}>{data.introduction}</div>
        </div>
        <div className={s.btnWrap}>
          {
            !isTuringAppWebView &&
            <div className={s.leftBtn} onClick={() => handleClick(data.courseLinkUrl)}>免费课程</div>
          }
          <div className={s.rightBtn} onClick={() => handleClick(data.studyLinkUrl)}>边看边做</div>
        </div>
      </div>
  </div>
</div>
}


export default React.memo(WorksPlay);