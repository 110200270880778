// 人气周榜格式的workcard
import React, { memo, ReactNode } from 'react'
import Avatar from '../Avatar'

/** @ts-ignore */
import s from './weeklyworkcard.module.sass'

import { POSTER1 } from '../../cdnmap'
import { num2likestr } from '../../util'

export interface WorkCardProps {
  id: string
  title: string
  imgUrl: string
  likeNum: number
  browseNum: number
  userName: string
  avatarUrl: string
  status?: number
  children?: ReactNode
}

export default memo(function WeeklyWorkCard({ onClick, isSelf, children, ...props }: WorkCardProps & {
  isSelf?: boolean
  onClick?: (props: WorkCardProps, withType?: string) => void
}) {
  const imgUrl = props.imgUrl || POSTER1

  return <div className={s.workcardBox} onClick={(e) => {
    e.stopPropagation()
    if (onClick) {
      onClick(props)
    }
  }}>
    {children}
    <div className={s.workcardPoster} style={{ backgroundImage: `url(${imgUrl})` }}></div>
    <div className={s.workcardContent}>
      <div className={s.workcardTitle}>{props.title || '未命名'}</div>
      <div className={`${s.workcardLine} ${s.workcardLine2}`} style={{ display: isSelf ? 'none' : '' }}>
        <div className={s.avatarCt}>
          <Avatar url={props.avatarUrl} size={20} />
        </div>
        <div className={s.workcardNickname}>{props.userName}</div>
      </div>
      <div className={s.workcardLine}>
        <div className={`${s.workcardIcon} ${s.eye}`}></div>
        <div className={s.workcardNumbers}>{num2likestr(props.browseNum)}</div>
        <div className={`${s.workcardIcon} ${s.like}`}></div>
        <div className={s.workcardNumbers}>{num2likestr(props.likeNum)}</div>
      </div>
    </div>
  </div >
})
