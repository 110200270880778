/**
 * 模板作品
 */

import React, {useEffect, useState, useRef} from 'react';

import ColumnHeader from '../ColumnHeader';
import WorksCard from './WorksCard';

import {WorksItem} from 'fetch/user';

import {leftIcon, rightIcon} from 'assets';
import s from './index.module.scss';

type IList = {id: number, list: WorksItem[]}[];

interface Props {
  data: WorksItem[]
}

const TemplateWorks: React.FC<Props> = ({
  data
}) => {

  const swipeDom = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0)
  const currPage = useRef(0)
  const [offset, setOffset] = useState(0)

  const list:IList = chunkList(data, 3)

  useEffect(() => {
    if(swipeDom.current) {
      const {width} = swipeDom.current.getBoundingClientRect();
      setWidth(width)
    }
  }, [swipeDom])

  const handleLeftClick = () => {
    if(currPage.current === 0) {
      return;
    }
    currPage.current = currPage.current - 1;
    setOffset(-(currPage.current)*width)
  }

  const handleRightClick = () => {
    if(currPage.current === list.length-1) {
      return;
    }
    currPage.current = currPage.current + 1;
    setOffset(-(currPage.current)*width)
  }

  return (
    <>
      <div className={s.wrap}>
        <ColumnHeader title="模板作品" desc="边看边做你也可以！" />
        <div className={s.sliderBtnWrap}>
          <span className={s.leftBtn} onClick={handleLeftClick}>
            <img src={leftIcon} alt="" />
          </span>
          <span className={s.rightBtn} onClick={handleRightClick}>
            <img src={rightIcon} alt="" />
          </span>
        </div>
        <div className={s.list} ref={swipeDom}>
          <div className={s.swipeWrap} style={{transform: `translateX(${offset}px)`}}>
          {
            list.map((item) => {
              return <div key={item.id} className={s.swipeItem}>
                {item.list.map(i => <WorksCard data={i} key={i.name} />)}
              </div>
            })
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateWorks;


/**
 * 将数组按照一定的chunkNum 分割成二维数组
 * @param list 
 * @param chunkNum 
 * @returns [[{id: string, list: [item]}]]
 */
function chunkList<T>(list: T[], chunkNum: number) {
  const num = Math.ceil(list.length/chunkNum);
  return Array(num).fill([]).reduce((acc, _, index ) => {
    const pos = index*chunkNum;
    return [
      ...acc,
      {
        id: index,
        list: [...list.slice(pos, pos+chunkNum)]
      }
    ]
  }, [])
}
