/**
 * 首页各专栏的title部分
 */
import { useRouter } from 'next/router';

import ArrowRight from 'components/ArrowRight';
import s from './index.module.scss';

function ColumnHeader({title, desc, morelink}: {title:string, desc: string, morelink?: string}) {
  const router = useRouter()
  
  return (
    <div className={s.columnHeader}>
      <div className={s.columnContent}>
        <div className={`${s.columnTitle} ${s.columnTitleBg}`}>
          {title}<span className={s.columnDesc}>{desc}</span>
        </div>
      </div>
      {morelink && <div className={s.morelink} onClick={() => {router.push(morelink)}}>
        <ArrowRight text="更多" />
      </div>}
    </div>
  )
}

export default ColumnHeader;